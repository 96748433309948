// eslint-disable-next-line boundaries/element-types
import { City } from 'entities/object';
import { SelectOption } from 'shared/types';

export function extractCities(citiesData: City[]): SelectOption[] {
  return citiesData.map((city) => ({
    value: city.id.toString(),
    label: city.name,
  }));
}

export function extractAreaByCityId(
  cityId: string,
  citiesData: City[]
): SelectOption[] {
  const city = citiesData.find((city) => city.id === Number(cityId));
  if (!city) {
    return [];
  }

  return city.districts
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((region) => ({
      value: region.id.toString(),
      label: region.name,
    }));
}

export function extractMicrodistrictsByAreaId(
  cityId: string,
  areaId: string,
  citiesData: City[]
): SelectOption[] {
  const city = citiesData.find((city) => city.id === Number(cityId));
  if (!city) {
    return [];
  }

  const region = city.districts.find(
    (district) => district.id === Number(areaId)
  );
  if (!region) {
    return [];
  }

  const sortedMicrodistricts = [...region.microdistricts].sort(
    (a, b) => a.position - b.position
  );

  return sortedMicrodistricts.map((microdistrict) => ({
    value: microdistrict.id.toString(),
    label: microdistrict.name,
  }));
}
