import { useEffect, useRef } from 'react';

export const usePrevious = <T>(
  value: T
): { previous: T | undefined; reset: (value: T) => void } => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  });

  const reset = (value: T) => {
    ref.current = value;
  };

  return { previous: ref.current, reset };
};
