import { SelectOption } from 'shared/types';
import { NumberingFormatType } from './types/lotSchema';

export const numberingMaskOption: SelectOption[] = [
  {
    value: NumberingFormatType.number,
    label: 'Число',
  },
  {
    value: NumberingFormatType.numberLetter,
    label: 'ЧислоБуква',
  },
  {
    value: NumberingFormatType.numberNumber,
    label: 'Число-Число',
  },
  {
    value: NumberingFormatType.numberSlashNumber,
    label: 'Число/Число',
  },
  {
    value: NumberingFormatType.numberPointNumber,
    label: 'Число.Число',
  },
  {
    value: NumberingFormatType.letterNumberNumber,
    label: 'БукваЧисло-Число',
  },
  {
    value: NumberingFormatType.numberLetterNumber,
    label: 'ЧислоБукваЧисло',
  },
  {
    value: NumberingFormatType.numberNumberLetter,
    label: 'Число/ЧислоБуква',
  },
];

export const numberingMaskPlaceholder: SelectOption[] = [
  {
    value: NumberingFormatType.number,
    label: '№',
  },
  {
    value: NumberingFormatType.numberLetterNumber,
    label: '№A№',
  },
  {
    value: NumberingFormatType.numberNumber,
    label: '№-№',
  },
  {
    value: NumberingFormatType.numberSlashNumber,
    label: '№/№',
  },
  {
    value: NumberingFormatType.numberNumberLetter,
    label: '№/№A',
  },
  {
    value: NumberingFormatType.letterNumberNumber,
    label: 'A№-№',
  },
  {
    value: NumberingFormatType.numberLetter,
    label: '№A',
  },
  {
    value: NumberingFormatType.numberPointNumber,
    label: '№.№',
  },
];
