import { Lot } from 'entities/chess';
import { getPositionMapKeyByPosition } from './positionMap';

export const getShowSplitButtonInDialog = (
  lots: Lot[],
  selectedLots: Lot[],
  lotsPositionsMap: Map<string, number>
) => {
  const selectedLotsPositions = selectedLots.flatMap((selectedLot) =>
    selectedLot.positions.map((position) =>
      getPositionMapKeyByPosition(position)
    )
  );
  const selectedPositionMap = new Map(
    Array.from(lotsPositionsMap).filter(([key]) =>
      selectedLotsPositions.includes(key)
    )
  );

  return selectedLotsPositions.every((position) => {
    //для каждой выбранной позиции проверяем влезет ли в ячейку еще столько же лотов, сколько в ней уже есть
    const placedLotCount = lotsPositionsMap.get(position);
    const newLotCount = selectedPositionMap.get(position);

    if (!placedLotCount || !newLotCount) {
      return false;
    }

    // Если выбран только один объединенный лот
    if (selectedLots.length === 1 && selectedLots[0].positions.length > 1) {
      return true;
    }

    const maxLotsInOneCell = 4;

    return placedLotCount + newLotCount <= maxLotsInOneCell;
  });
};
