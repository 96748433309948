import { Lot } from 'entities/chess';
import { areAllLotsNeighbors } from './areAllLotsNeighbors';
import { getPositionMapKeyByPosition } from './positionMap';

export const getShowMergeButtonInDialog = (
  lots: Lot[],
  selectedLots: Lot[],
  lotsPositionsMap: Map<string, number>
) => {
  if (selectedLots.length <= 1) {
    return false;
  }

  const hasSplittedLot = lots.some((lot) =>
    selectedLots.some((selectedLot) => {
      if (lot.id === selectedLot.id) {
        const lotsInCell = lotsPositionsMap.get(
          getPositionMapKeyByPosition(lot.positions[0])
        );
        return lotsInCell && lotsInCell > 1;
      }
      return false;
    })
  );

  if (hasSplittedLot) {
    const isAllPositionsSame = selectedLots.every((lot) =>
      lot.positions.every(
        (position) =>
          position.floor === selectedLots[0].positions[0].floor &&
          position.riser === selectedLots[0].positions[0].riser
      )
    );
    if (!isAllPositionsSame) {
      return false;
    }
  }

  const isAllLotsNeighbors = areAllLotsNeighbors(selectedLots);
  return isAllLotsNeighbors;
};
