import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { objectZod, ObjectLocationEditForm } from 'entities/object';
import { CardLayout } from 'shared/ui/CardLayout';

type StepTwoProps = {
  onDone: () => void;
  onReturn: () => void;
  onStepValidityChange: (isStepValid: boolean) => void;
};

const stepTwoZod = objectZod.pick({
  address: true,
  latitude: true,
  longitude: true,
  city: true,
  area: true,
  microdistrict: true,
  seaDistance: true,
});

const formFields = [
  'address',
  'microdistrict',
  'seaDistance',
  'latitude',
  'longitude',
  'city',
  'area',
];

export const StepTwo: FC<StepTwoProps> = ({
  onDone,
  onReturn,
  onStepValidityChange,
}) => {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState<boolean>(false);
  const form = useFormContext();

  useEffect(() => {
    const subscription = form.watch(() => {
      const [
        address,
        microdistrict,
        seaDistance,
        latitude,
        longitude,
        city,
        area,
      ] = form.getValues(formFields);

      setIsValid(
        stepTwoZod.safeParse({
          address,
          microdistrict,
          seaDistance,
          longitude,
          latitude,
          city,
          area,
        }).success
      );
    });

    return () => subscription.unsubscribe();
  }, [form]);

  useEffect(() => {
    onStepValidityChange(isValid);
  }, [isValid, onStepValidityChange]);

  return (
    <CardLayout
      header={{
        title: t('object.create.stepTwo.title'),
        subtitle: t('object.create.stepTwo.form.subtitle'),
      }}
      footer={{
        onOkClick: isValid ? onDone : () => form.trigger(formFields),
        onCancelClick: onReturn,
        cancelLabel: t('ui.common.return'),
      }}
      width={648}
    >
      <ObjectLocationEditForm />
    </CardLayout>
  );
};
