import { FC, useCallback, useEffect, useState } from 'react';
import {
  Link,
  useNavigate,
  useOutlet,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Card, Tab, Tabs, Typography } from '@mui/material';
import {
  DeveloperObject,
  DeveloperObjectOutletData,
  EditDeveloperObject,
  editObjectZod,
  useUpdateObjectMutation,
  useVersionCheck,
} from 'entities/object';
import {
  SalesOffice,
  useDeleteSalesOfficeMutation,
  useUpdateSalesOfficeMutation,
} from 'entities/salesOffice';
import { getRoute } from 'shared/const';
import { decodeHtmlEntities } from 'shared/helpers/decodeHtmlEntities';
import { getFormErrorsArray } from 'shared/helpers/getFormErrorsArray';
import { useRouteMatch } from 'shared/hooks/useRouteMatch';
import { useSnackbar } from 'shared/hooks/useSnackbar';
import { CardLayout } from 'shared/ui/CardLayout';
import { Col } from 'shared/ui/Col';
import { ErrorBoundary } from 'shared/ui/ErrorBoundary';

const OBJECT_TYPE_COTTAGES = '2';

export const EditObject: FC = () => {
  const { t } = useTranslation();
  const { objectId } = useParams<{ objectId: string }>();
  const routeMatch = useRouteMatch([
    getRoute.EditObjectDetailsGeneral(':objectId'),
    getRoute.EditObjectDetailsLocation(':objectId'),
    getRoute.EditObjectDetailsCharacteristics(':objectId'),
  ]);
  const { checkVersion, showVersionChangedPopup } = useVersionCheck(objectId);

  const currentTab = routeMatch?.pattern?.path;

  const storedObject = useOutletContext<DeveloperObject>();
  const [updateObject, { isLoading: isObjectUpdating }] =
    useUpdateObjectMutation();
  const [updateSalesOffice, { isLoading: isSalesOfficeUpdating }] =
    useUpdateSalesOfficeMutation();
  const [deleteSalesOffice, { isLoading: isSalesOfficeDeleting }] =
    useDeleteSalesOfficeMutation();
  const [objectSalesOffices, setObjectSalesOffices] = useState<SalesOffice[]>(
    storedObject.salesOffices
  );
  const { showSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const form = useForm<EditDeveloperObject>({
    resolver: zodResolver(editObjectZod),
    mode: 'all',
    defaultValues: {
      name: storedObject.name || '',
      site: storedObject.site || '',
      objectType: storedObject.objectType || '',
      houseType: storedObject.houseType || '',
      saleStatus: storedObject.saleStatus || '',
      objectDescription:
        decodeHtmlEntities(storedObject.objectDescription) || '',
      address: storedObject.address || '',
      latitude: storedObject.latitude,
      longitude: storedObject.longitude,
      city: storedObject.city,
      area: storedObject.area,
      microdistrict: storedObject.microdistrict || '',
      seaDistance: storedObject.seaDistance || '',
      federalLow: storedObject.federalLow || '',
      houseClass: storedObject.houseClass || '',
      material: storedObject.material || '',
      territory: storedObject.territory || '',
      parking: storedObject.parking || [],
      advantages: storedObject.advantages || [],
      entrances: storedObject.entrances || '',
      isNewObject: storedObject.isNewObject,
      ceilingHeight: storedObject.ceilingHeight || '',
      maintenancePrice: storedObject.maintenancePrice || '',
      gasPrice: storedObject.gasPrice || '',
      communicationsPrice: storedObject.communicationsPrice || '',
      documentsPrice: storedObject.documentsPrice || '',
      otherData: storedObject.otherData || '',
      commission: storedObject.commission || '',
      canalization: storedObject.canalization || '',
      heating: storedObject.heating || '',
      waterSupply: storedObject.waterSupply || '',
      electricity: storedObject.electricity || '',
      gas: storedObject.gas || '',
      contractors:
        storedObject.contractors?.map((contractor) => contractor.id) || [],
      salesOffices:
        storedObject.salesOffices.map((salesOffice) => salesOffice.id) || [],
      developerId: storedObject.developerId,
      id: storedObject.id,
      published: storedObject.published,
      nedvex: storedObject.nedvex,
    },
  });

  useEffect(() => {
    const subscription = form.watch(() => {
      const objectTypeValue = form.getValues('objectType');
      const houseTypeValue = form.getValues('houseType');

      if (objectTypeValue === OBJECT_TYPE_COTTAGES && houseTypeValue) {
        form.setValue('houseType', '');
      }
    });

    return () => subscription.unsubscribe();
  }, [form]);

  const handleCancelStep = useCallback(() => {
    navigate(getRoute.Object(storedObject.id));
  }, [navigate, storedObject.id]);

  const handleSaveObject = async (objectData: EditDeveloperObject) => {
    try {
      const isVersionChanged = await checkVersion();

      if (!isVersionChanged) {
        const existingSalesOfficeIds = form.getValues('salesOffices');

        const filteredSalesOfficeIds = objectSalesOffices
          .filter(
            (salesOffice) =>
              !existingSalesOfficeIds.includes(salesOffice.id) &&
              !salesOffice.isDeleted
          )
          .map((salesOffice) => salesOffice.id);

        await updateObject({
          ...storedObject,
          ...objectData,
          salesOffices: filteredSalesOfficeIds,
        }).unwrap();

        const salesOfficesIdsForDelete = objectSalesOffices
          .filter((salesOffice) => salesOffice.isDeleted)
          .map((salesOffice) => salesOffice.id);

        const deletePromises = salesOfficesIdsForDelete.map((salesOfficesId) =>
          deleteSalesOffice({
            objectId: storedObject.id,
            officeId: salesOfficesId,
          })
        );

        const salesOfficesForUpdate = objectSalesOffices.filter(
          (salesOffice) => salesOffice.isEdited
        );

        const updatePromises = salesOfficesForUpdate.map((salesOffice) =>
          updateSalesOffice({ objectId: storedObject.id, salesOffice })
        );

        await Promise.all([...deletePromises, ...updatePromises]);

        navigate(getRoute.Object(storedObject.id));
      }
    } catch (e) {
      console.error(e);
      showSnackbar();
    }
  };

  const handleAddSalesOffice = (editedSalesOffice: SalesOffice) => {
    const salesOfficeExists = objectSalesOffices.some(
      (salesOffice) => salesOffice.id === editedSalesOffice.id
    );

    let updatedSalesOffices;

    if (salesOfficeExists) {
      updatedSalesOffices = objectSalesOffices.map((salesOffice) =>
        salesOffice.id === editedSalesOffice.id
          ? { ...editedSalesOffice, isEdited: true }
          : salesOffice
      );
    } else {
      updatedSalesOffices = [...objectSalesOffices, editedSalesOffice];
    }

    setObjectSalesOffices(updatedSalesOffices);
  };

  const handleDeleteSalesOffice = (deletedSalesOffice: SalesOffice) => {
    const updatedSalesOffices = objectSalesOffices.map((salesOffice) =>
      salesOffice.id === deletedSalesOffice.id
        ? { ...deletedSalesOffice, isDeleted: true }
        : salesOffice
    );
    setObjectSalesOffices(updatedSalesOffices);
  };

  const outlet = useOutlet({
    storedObject,
    objectSalesOffices,
    handleAddSalesOffice,
    handleDeleteSalesOffice,
  } as DeveloperObjectOutletData);

  if (!objectId) {
    navigate(getRoute.Objects());
    return null;
  }

  return (
    <Col spacing={8}>
      {showVersionChangedPopup()}
      <Card sx={{ width: 648 }}>
        <FormProvider {...form}>
          <Tabs value={currentTab}>
            <Tab
              label={
                <Typography variant={'accentS'}>
                  {t('object.edit.tabLabel.mainData')}
                </Typography>
              }
              component={Link}
              value={getRoute.EditObjectDetailsGeneral(':objectId')}
              to={getRoute.EditObjectDetailsGeneral(objectId)}
            />
            <Tab
              label={
                <Typography variant={'accentS'}>
                  {t('object.edit.tabLabel.location')}
                </Typography>
              }
              component={Link}
              value={getRoute.EditObjectDetailsLocation(':objectId')}
              to={getRoute.EditObjectDetailsLocation(objectId)}
            />
            <Tab
              label={
                <Typography variant={'accentS'}>
                  {t('object.edit.tabLabel.characteristics')}
                </Typography>
              }
              component={Link}
              value={getRoute.EditObjectDetailsCharacteristics(':objectId')}
              to={getRoute.EditObjectDetailsCharacteristics(objectId)}
            />
          </Tabs>
          <ErrorBoundary key={currentTab} showReloadButton>
            <CardLayout
              disableBorder
              footer={{
                onOkClick: form.handleSubmit(handleSaveObject),
                onCancelClick: handleCancelStep,
                okLabel: t('ui.common.save'),
                cancelLabel: t('ui.common.cancel'),
                okButtonLoading:
                  isObjectUpdating ||
                  isSalesOfficeUpdating ||
                  isSalesOfficeDeleting,
              }}
              errors={getFormErrorsArray(form.formState.errors)}
            >
              {outlet}
            </CardLayout>
          </ErrorBoundary>
        </FormProvider>
      </Card>
    </Col>
  );
};
