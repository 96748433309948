import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Typography,
} from '@mui/material';
import { appendAsterisk } from 'shared/helpers/appendAsterisk';
import { PaletteColor, TextColor } from 'shared/theme/colors';
import { SelectOption } from 'shared/types';
import { Row } from './Row';

type SelectFieldProps = {
  options: SelectOption[];
  label: string;
  noTranslate?: boolean;
  required?: boolean;
  onChange?: (event: SelectChangeEvent) => void;
} & Omit<SelectProps<string>, 'value' | 'defaultValue'>;

export const SelectField: FC<SelectFieldProps> = ({
  options,
  label,
  name,
  noTranslate,
  required,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      name={name || ''}
      control={control}
      render={({ field: { ref, ...rest }, fieldState: { error } }) => (
        <FormControl
          variant="filled"
          fullWidth
          error={Boolean(error)}
          sx={props.sx}
        >
          <InputLabel>
            <Typography
              sx={{
                color: TextColor.Secondary,
              }}
            >
              {appendAsterisk(label, required, !!error)}
            </Typography>
          </InputLabel>
          <Select
            {...props}
            {...rest}
            onChange={(event) => {
              rest.onChange(event);
              if (onChange) {
                onChange(event);
              }
            }}
            sx={{
              border: error ? `1px solid ${PaletteColor.Error}` : 'none',
              '& .MuiSvgIcon-root': {
                color: TextColor.Primary,
              },
              ...props.sx,
            }}
            renderValue={(selected) => {
              const selectedOption = options.find(
                (option) => option.value === selected
              );
              if (!selectedOption) return '';
              return (
                <Typography sx={{ color: TextColor.Primary }}>
                  {noTranslate
                    ? selectedOption.label
                    : `${t(selectedOption.label)}${
                        selectedOption.sublabel
                          ? `, ${t(selectedOption.sublabel)}`
                          : ''
                      }`}
                </Typography>
              );
            }}
          >
            {options.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {noTranslate ? (
                  option.label
                ) : (
                  <Row sx={{ justifyContent: 'space-between', width: '100%' }}>
                    <Typography>{t(option.label)}</Typography>
                    {option.sublabel && (
                      <Typography
                        sx={{
                          color: TextColor.Secondary,
                        }}
                      >
                        {t(option.sublabel)}
                      </Typography>
                    )}
                  </Row>
                )}
              </MenuItem>
            ))}
          </Select>
          {error && error.message && (
            <FormHelperText>{t(error.message)}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};
